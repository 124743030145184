@font-face {
  font-family: "MuliBlack";
  src:    
    url("../assets/fonts/MuliBlack.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MuliBold";
  src:
    url("../assets/fonts/MuliBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Muli-BoldItalic";
  src:
    url("../assets/fonts/Muli-BoldItalic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MuliRegular";
  src:
    url("../assets/fonts/MuliRegular.ttf") format("truetype");
  font-weight: normal;
}
