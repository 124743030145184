.create-temp-contest {
  .inner-div-container{
    margin: 0 20px;
    float: left;
  }
  .card {    background-color: #EFEFEF;    border: none;}
  margin-top: 30px;
  .carddiv {
    display: flex;
    margin-top: 8px;
    background-color: transparent !important;
  }
  .match-with {
    display: flex;
    flex-grow: 0;
  }
  .cardimgdfs {
    width: 68px;
    height: 68px;
  }
  .livcardh3dfs {
    height: 30px;
    color: $black;
    font-family: $font-black;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 4px;
  }
  .livcardh6dfs {
    width: 150px;
    color: #a8a8a8;
    font-family: $font-black;
    font-size: 10px;
    text-align: center;
    margin-bottom: 4px;
  }
  .dfsrow.row {
    margin-top: 1rem;
    justify-content: space-between;
  }
  .dfssports {
    font-family: $font-black;
    font-size: 24px;
    margin-bottom: 18px;
  }
  .backtofixtures {
    color: #666666;
    font-family: $font-black;
    cursor: pointer;
    margin-bottom: 0.5rem;
    i {
      transform: rotate(90deg);
      font-size: 12px;
      margin-right: 5px;
      margin-top: -1px;
    }
  }

  .contest-tempalte-wrapper {
    .h3-cls {
      margin-bottom: 4px;
    }
    .info-text {
      color: $gray-9;
      font-family: $font-bold;
      margin-bottom: 0;
    }
  }
  .contest-group-container {
    margin: 30px 0 0 0;

    h4 {
      margin-bottom: 18px;
      font-family: $font-bold;
      font-size: 20px;
    }
  }
  &:last-child {
    border-bottom: none;
  }

  .contest-card {
    border-radius: 16px;
    background-color: $white;
    margin-bottom: 52px;

    .contest-card-body {
      border-radius: 16px;
      position: relative;
      background-color: $white;
      box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
      margin-top: 5px;
      display: inline-block;
      width: 100%;
      height: 126px;
      .contest-list-header {
        padding: 17px 15px;
        cursor: pointer;
        text-align: left;
        .contest-heading {
          position: relative;
          margin-bottom: 8px;

          .contest-name {
            font-size: 12px;
            color: #212121;
            font-family: $font-bold-italic;
            margin-bottom: 5px;
          }
          .alphabets-icon {
            position: absolute;
            right: 0 !important;
            left: unset !important;
            top: 0;
            width: 90px;
            text-align: right;
            .contest-type {
              margin-left: 10px;
              display: inline-block;
              font-size: 20px;
              color: #20a8d8;
            }
          }

          .win-type {
            font-size: 16px;
            color: #9e9e9e;
            margin: 0;
            span {
              color: #0cbfeb;
              font-weight: 800;
              font-size: 17px;
              .prize-pool-text {
                font-size: 16px;
                color: $gray-9;
                font-family: $font-black;
                font-weight: 800;
              }
              .prize-pool-value {
                i {
                  vertical-align: baseline;
                  font-size: 15px;
                  padding-right: 2px;
                }
              }
            }
          }
          .text-small-italic {
            font-size: 10px;
            font-family: $font-bold-italic;
            color: $gray-9;
            :first-child {
              text-decoration: underline
            }
          }
        }

        .progress-bar-default {
          float: left;
          width: 75%;
          .progress {
            border-radius: 3px;
            height: 4px;
            width: 100%;
          }
          .progress-bar {
            border-radius: 3px;
          }
          .progress-bar-value {
            color: #333333;
            font-size: 11px;
            margin-top: 5px;
            position: relative;
          }
          .user-joined {
            color: #20a8d8;
            font-weight: 600;
          }
          .total-entries {
            color: #9b9b9b;
            font-size: 12px;
            font-weight: 600;
          }
          .total-output {
            color: #333333;
            font-size: 16px;
          }
          .min-entries {
            font-size: 12px;
            color: #a9a9a9;
            position: absolute;
            font-style: italic;
            right: 9%;
            top: -1px;
            @media (min-width: 415px) {
              right: 0%;
              left: auto;
            }
          }
        }
        .entry-criteria {
          width: 25%;
          float: left;
          text-align: right;
        }
        .btn-light-rounded,
        .btn-rounded {
          border-radius: 15px;
          font-family: $font-regular;
          padding: 5px 10px;
          font-size: 12px;
          height: 30px;
          width: 68px;
          span {
            vertical-align: baseline;
            img {
              margin-top: -4px;
              width: 15px;
              margin-right: 3px;
            }
          }
        }
      }
    }
    .league-listing-wrapper {
      border-radius: 16px;
      box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
      padding: 10px 15px;

      .league-listing-container {
        display: inline-block;
        width: 100%;
        .sponsor-logo {
          img {
            height: 30px;
            width: 30px;
          }
        }
      }
      .contest-details-para {
        text-align: left;
        p {
          color: #555555;
          font-family: $font-regular;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 0;
        }
      }
      .league-ul-wrapper {
        width: 90%;
        float: left;
        text-align: left;
        ul {
          padding-left: 0;
          list-style-type: none;
          margin-bottom: 0;
          li {
            display: inline-block;
            .league-name {
              border: 1px solid #20a8d8;
              border-radius: 15px;
              color: $black;
              font-size: 10px;
              padding: 5px 10px;
              margin-right: 10px;
            }
          }
        }
      }
      .league-edit-container {
        float: left;
        width: 10%;
        text-align: right;
        i {
          font-size: 16px;
          margin-left: 14px;
          color: $black;
        }
      }
      &.details-para {
        padding: 5px 15px;
      }
    }
    &.contest-selected {
      border: 2px solid #20a8d8;
    }
  }
  .createtempcontest {
    position: relative;
    margin-top: 130px;
    margin-bottom: 50px;
    .bottom-container {
      position: absolute;

      bottom: 0;
      left: 50%;
      width: calc(100% - 200px);
      transform: translateX(-50%);

      text-align: center;
      padding: 20px 0 20px;

      p {
        opacity: 0.8;
        color: #ff0000;
        font-family: $font-bold-italic;
        font-size: 12px;
        font-style: italic;
        line-height: 30px;
        text-align: center;
        margin-bottom: 10px;
      }
      .btn {
        height: 42px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.fixture-contest-main1 {
  h4.livcardh3dfs {
      font-size: 16px;
      text-align: center;
  }
  .livcardh3 {font-weight: 800; 
    text-align: center;
    @media (max-width: 1367px) {
      font-size: 15px;
    }
  } 
  .cardimg {
    width: 40px;
    height: 40px;
  }
  .card {    background-color: #EFEFEF;    border: none;}
  .carddiv {
    display: table;
    width: 100%;
    margin-top: 8px;
    background-color: #fff;
    border: none;
    padding: 18px;
    margin: 10px;
    border-radius: 14px;
    .col {
      display: table-cell;
      vertical-align: middle;
      width: 25%;
      &:nth-child(2){
        width: 50%;
      }
        padding: 0 !important;
        @media (min-width: 1567px) {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
    }
  }
  .cardimgdfs {
    width: 68px;
    height: 68px;
  }
  .contest-listtable {
    display: block !important;
    .cardimgdfs {
      width: 20px;
      height: 22px;
    }
    .livcardh3dfs { 
      color: #030f09;
      font-family: $font-black;
      font-size: 16px;
      font-weight: 800;
      line-height: 18px;
      margin-bottom: 0;
      text-align: center;
      width: auto;
      
    }
  }
  .livcardh6dfs {
    height: 13px;
    width: 100%;
    text-align: center;
    color: #a8a8a8;
    font-family: $font-black;
    font-size: 10px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
    margin-bottom: 4px;
  }
  
}