@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot?9bbgrb#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?9bbgrb') format('truetype'),
    url('../assets/fonts/icomoon.woff?9bbgrb') format('woff'),
    url('../assets/fonts/icomoon.svg?9bbgrb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-down-substitute:before {
  content: "\e93b";
}

.icon-glasshour:before {
  content: "\e93c";
}

.icon-up-substitute:before {
  content: "\e93d";
}

.icon-substitute:before {
  content: "\e93e";
}
.icon-wallet:before {
  content: "\e911";
}

.icon-export:before {
  content: "\e914";
}

.icon-Pending:before {
  content: "\e915";
}

.icon-verified:before {
  content: "\e916";
}

.icon-info-border:before {
  content: "\e90c";
}

.icon-bonus:before {
  content: "\e90d";
}

.icon-pinned:before {
  content: "\e902";
}

.icon-promote .path1:before {
  content: "\e90a";
  color: rgb(248, 67, 110);
}

.icon-promote .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-down-arrow:before {
  content: "\e909";
}

.icon-icon-m:before {
  content: "\e906";
}

.icon-icon-r:before {
  content: "\e907";
}

.icon-icon-g:before {
  content: "\e908";
}

.icon-edit:before {
  content: "\e903";
}

.icon-search:before {
  content: "\e904";
}

.icon-delete:before {
  content: "\e905";
}

.icon-rupess:before {
  content: "\e90e";
}

.icon-Path:before {
  content: "\e901";
}

.icon-Shape:before {
  content: "\e90f";
}

.icon-info:before {
  content: "\e900";
}

.icon-flag:before {
  content: "\e919";
}

.icon-flag_enable .path1:before {
  content: "\e912";
  color: rgb(248, 67, 110);
}

.icon-flag_enable .path2:before {
  content: "\e913";
  margin-left: -0.75em;
  color: rgb(66, 74, 96);
}

.icon-add_note:before {
  content: "\e917";
}

.icon-notify:before {
  content: "\e918";
}

.icon-wallet:before {
  content: "\e911";
}

.icon-email_verified:before {
  content: "\e91a";
}

.icon-not-verify:before {
  content: "\e91b";
}

.icon-inactive:before {
  content: "\e910";
}

.icon-filter:before {
  content: "\e91c";
}

.icon-notification:before {
  content: "\e91d";
}

.icon-sms:before {
  content: "\e91e";
}

.icon-export:before {
  content: "\e914";
}

.icon-action:before {
  content: "\e91f";
}

.icon-inactive-border:before {
  content: "\e920";
}

.icon-pending-doc:before {
  content: "\e921";
}

.icon-pending-document:before {
  content: "\e922";
}

.icon-coins .path1:before {
  content: "\e923";
  color: rgb(228, 175, 24);
}

.icon-coins .path2:before {
  content: "\e924";
  margin-left: -1.111328125em;
  color: rgb(196, 146, 20);
}

.icon-camera:before {
  content: "\e925";
}

.icon-bonus1:before {
  content: "\e926";
}

.icon-reset:before {
  content: "\e930";
}

.icon-star:before {
  content: "\e927";
}

.icon-close:before {
  content: "\e928";
}

.icon-setting:before {
  content: "\e92a";
}

.icon-question:before {
  content: "\e929";
}
.icon-add:before {
  content: "\e934";
}
.icon-calender:before {
  content: "\e933";
}
.icon-ic-play:before {
  content: "\e935";
}
.icon-promote1:before {
  content: "\e932";
}
.icon-verticle-more:before {
  content: "\e936";
}
.icon-cross:before {
  content: "\e937";
}
.icon-addmore:before {
  content: "\e938";
}
.icon-more:before {
  content: "\e92c";
}

.icon-pause:before {
  content: "\e92b";
}

.icon-refresh:before {
  content: "\e92d";
}

.icon-pinned-fill:before {
  content: "\e92e";
}

.icon-delete1:before {
  content: "\e92f";
}

.icon-share-fill:before {
  content: "\e931";
}

.icon-promote1:before {
  content: "\e932";
}

.icon-calender:before {
  content: "\e933";
}

.icon-ic-play:before {
  content: "\e935";
}

.icon-add:before {
  content: "\e934";
}

.icon-verticle-more:before {
  content: "\e936";
}

.icon-cross:before {
  content: "\e937";
}

.icon-addmore:before {
  content: "\e938";
}

.icon-plus:before {
  content: "\e939";
}

.icon-minus:before {
  content: "\e93a";
}
.icon-righttick:before {
  content: "\e93f";
}
.icon-down-substitute:before {
  content: "\e93b";
}

.icon-glasshour:before {
  content: "\e93c";
}

.icon-up-substitute:before {
  content: "\e93d";
}

.icon-substitute:before {
  content: "\e93e";
}

.icon-stats:before {
  content: "\e940";
}

.icon-delay:before {
  content: "\e941";
}

.icon-contest:before {
  content: "\e942";
}

.icon-Salary-update:before {
  content: "\e943";
}

.icon-salary-Review:before {
  content: "\e944";
}

.icon-select_player:before {
  content: "\e945";
}

.icon-fixture_published:before {
  content: "\e946";
}
.icon-fixture-contest:before {
  content: "\e947";
}

.icon-template:before {
  content: "\e948";
}

.icon-switch-pos:before {
  content: "\e949";
}
.icon-SW:before {
  content: "\e94a";
}