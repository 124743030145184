.contestlist-dashboard {
  .dfsrow.row {
    margin-top: 30px;
  }

  .filter-label {
    color: $gray-5;
    font-family: $font-bold;
    margin-top: 10px;
    margin-right: 8px;
  }

  .contest-dashboard-dropdown {
    margin-top: 36px;
    margin-bottom: 44px;
  }

  .league-filter {
    width: 180px;
    float: left;
    margin-right: 15px;

    @media (max-width: 1400px) {
      width: 170px !important;
      min-width: unset;
    }
  }

  .select-wrapper {
    .Select-control {
      height: 42px;
      border: 1px solid #b8c4cb;
      border-radius: 4px;
      background-color: #f5f9fb;

      .Select-value,
      .Select-placeholder {
        line-height: 42px;
      }
    }
  }

  .search-wrapper {
    input {
      border: 1px solid #b8c4cb;
      border-radius: 4px;
      background-color: #f5f9fb;
      height: 42px;
      width: 210px;
    }

    i {
      position: absolute;
      right: 0;
      width: 25px;
      z-index: 999;
      top: 12px;
      color: $gray-9;
    }
  }

  .contestcard {
    margin-bottom: 10px;
    width: 100%;
    background-color: $white;
    border: none;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

  .table {
    margin-bottom: 0;
    text-align: center;

    th {
      vertical-align: middle;
      border: none;
      width: 200px;
    }

    td {
      vertical-align: middle;
      border: none;
      padding: 0;
      width: 200px;
      color: $black;
      font-family: $font-bold;
      font-size: 18px;
    }
  }

  td.contest-column,
  th.contest-column {
    width: 320px;
    text-align: center;

    @media (max-width: 1620px) {
      width: 440px;
    }
  }

  .card-body {
    padding: 10px;
  }

  .contest-dashboard-btn {
    font-family: $font-bold-italic;
    font-size: 16px;
    border: none;
    background-color: transparent;
    color: #4a4a4a;

    @media (max-width: 1400px) {
      font-size: 15px;
    }

    &:active {
      background-color: transparent;
      border: none;
    }

    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }

    &.dropdown-toggle::after {
      display: inline-block;
      margin-left: 6px;
      vertical-align: 0.255em;
      content: "";
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
      display: none;
    }
  }

  .dropdown {
    .fa-sort-desc {
      vertical-align: baseline;
      font-size: 20px;
    }

    .fa-sort-asc {
      vertical-align: bottom;
      font-size: 20px;
    }
  }

  .contest-column {
    width: 250px;
    text-align: center;
  }

  .contest-table-p {
    font-size: 20px;
    font-family: $font-bold;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    color: $text-color;

    .line-text-ellipsis {
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
      overflow-y: hidden;
      height: 27px;
    }

    @media (max-width: 1400px) {
      font-size: 15px;
    }

    @media (max-width: 1367px) {
      font-size: 12px;
      height: 26px;
    }
  }

  .contest-table-p {
    .alphabets-icon {
      position: unset;
      left: 0;
      top: 0;
      width: unset;
      text-align: left;
      vertical-align: top;

      .contest-type {
        margin-left: 10px;
        display: inline-block;
        font-size: 20px;
        color: #20a8d8;

        @media (max-width: 1400px) {
          font-size: 18px;
        }
      }
    }
  }

  .cardimgdfs {
    width: 22px;
    height: 20px;
  }

  .livcardh3dfs {
    font-family: $font-black;
    font-size: 16px;
    margin-right: 1rem;

    @media (max-width: 1620px) {
      font-size: 13px;
    }
  }

  .cd-act-btns {
    padding: 2px 6px;
    font-size: 12px;
    margin: 0 4px;
  }
}

.showFixture:hover #showMoreFixture {
  display: block;
}

.showFixture #showMoreFixture {
  display: none;
  position: absolute;
  top: 53px;
  width: 250px;
  left: 0px;
  background-color: $white;
  z-index: 9999;
}

.showFixture1 #showMoreFixture {
  display: none;
}

.collection-vd {
  border-color: #40BFEB !important;
  text-align: center;
  width: 350px;
  border-radius: 14px;

  .collection_vertically {
    position: absolute;
    z-index: 1;
    top: 0%;
    background-color: #40BFEB;
    border-bottom-right-radius: 14px;
    border-top-right-radius: 14px;
    color: #fff;

    .icon-info {
      color: #fff;
      font-size: 10px;
    }

    writing-mode: tb-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    display: block;
    bottom: 0;
    width: 20px;
    height: 124px;
    float: left;
    letter-spacing: 2px;
    font-size: 10px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 5px;
    width: 20px;
    height: 80%;
    letter-spacing: 1px;

    @media (max-width: 1366px) {

      height: 76px !important;
    }

  }
}


.fxcon-total-box {
  padding: 10px 16px;
  height: 70px;
  width: 200px;
  border-radius: 6px;
  float: left;
  margin-right: 16px;
  background-color: $white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .06);

  .fxcon-title {
    color: #a8a8a8;
    font-family: $font-bold;        
  }

  .fxcon-count {
    color: #2c2f3a;
    font-family: $font-bold;
    font-size: 24px;
  }
}
