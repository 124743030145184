.circle {
  animation: 1s infinite alternate circle;
}

@keyframes circle {
  from {
    width : 10px;
    height: 10px;
  }

  to {
    width : 6px;
    height: 6px;
  }
}

.app-body.true::after {
  position        : fixed;
  right           : 0;
  left            : 0;
  content         : "";
  display         : inline-block;
  width           : 100%;
  height          : 100%;
  z-index         : 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.notification-alert {
  // width : 8px;
  // height: 8px;

  background-color: red;
  display         : block;
  border-radius   : 100%;
  position        : absolute;
  right           : 10px;
  top             : 4px;
}

.app-header {
  position        : relative;
  flex-direction  : row;
  height          : 60px;
  border          : none !important;
  padding         : 0;
  margin          : 0;
  border-bottom   : 1px solid;
  background-color: $theme-color;
  box-shadow      : 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .navbar-toggler {
    min-width: 50px;
    padding  : 0.25rem 0;
    position : relative;
  }

  .navbar-brand {
    width: 250px;
    img{
      width: 200px !important;
    }
  }

  .sports-selector-container {
    .sports-seletor {
      background-color: #212235;
      height          : auto;
      width           : 150px;

      .Select-control {
        background-color: transparent !important;
        border          : none;
        margin          : 0;

        .Select-value-label {
          color      : $white !important;
          line-height: 17px !important;
        }

        .Select-arrow {
          &:after {
            color: $white;
          }
        }
      }

      .Select-value {
        background: transparent;
      }
    }
  }

  .nav-item {
    min-width: 44px;
  }

  .top-navigation {
    .nav-icon {
      height: 22px;
      width : 22px;
      cursor: pointer;
    }

    .btn.btn-default {
      border: 1px solid $white;
      margin: 0 20px 0 10px;
    }

    .dropdown-menu {
      left            : 20px !important;
      top             : 14px !important;
      border          : none;
      height          : 122px;
      background-color: $white;
      box-shadow      : 0 0 10px 0 rgba(0, 0, 0, 0.15);
      border-radius   : 12px;

      &::after {
        content     : '';
        width       : 0;
        height      : 0;
        position    : absolute;
        top         : -10px;
        right       : 26px;
        border-left : 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
      }

      .dropdown-item.active,
      .dropdown-item:active,
      .dropdown-item:hover,
      .dropdown-item:focus {
        outline         : none;
        border-radius   : 12px;
        background-color: $white;
        box-shadow      : 0 0 10px 0 rgba(0, 0, 0, 0.15);
      }
    }

    .bell-items {
      padding: 10px 40px;

      .bell-list {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 60px;
        }

        .bell-text {
          color      : #000000;
          font-family: $font-regular;
          font-size  : 14px;
          line-height: 18px;
          margin-top : 12px;
          display    : block;
          cursor     : pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        img {
          height: 36px;
          width : 39px;
        }
      }
    }
  }
}
