.create-dfs-contest {
  .guaranteed-box {
    .guaranteed-contest {
      font-size: 16px;
      font-weight: bold;
      color: #F8436E;
      margin-right: 20px;
    }

    .btn {
      padding: 2px 12px;
    }
  }

  margin-top: 20px;

  .form-group {
    margin-bottom: 0;
    width: 320px;

    @media (max-width: 1800px) {
      width: 100%;
    }
  }

  .info-box {
    margin-left: 8px;
    position: absolute;
    top: 6px;
  }

  .custom-control-label {
    font-size: 16px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .multiple_lineup .Select-control {
    height: 48px;
    border: 1px solid #d2dfec;
    background-color: #f5f9fb;
    box-shadow: 0 2px 15px 1px rgba(184, 181, 181, 0.5);

    .Select-placeholder {
      line-height: 46px;
    }

    .Select-value-label {
      line-height: 48px;
    }
  }

  .verifyrow {
    text-align: center;

    button {
      border: 2px solid $secondary-color !important;
      font-weight: bold;
      color: #222222 !important;

      &:hover {
        color: $white !important;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .recentcom.card {
    box-shadow: 0px 3px 15px lightgrey;
    border: none;
  }

  .contestcreate.card-header {
    background-color: $theme-color;
    height: 52px;
    width: 100%;
  }

  .DFScontest {
    color: $white;
    font-family: $font-bold;
    font-size: 18px;
  }

  .fixturevs {
    display: block;
    color: $black;
    font-family: $font-black;
    font-size: 16px;
    margin-bottom: 14px;
  }

  .multiselect-wrapper {
    .dropdown-content {
      .select-item {
        background-color: transparent !important;
        text-align: left;

        input[type="checkbox"] {
          float: right;
        }
      }
    }

    .dropdown-heading-dropdown-arrow {
      border: none !important;

      :after {
        content: "\e90f";
        border: none !important;
        position: absolute !important;
        height: 5px !important;
        width: 5px !important;
        right: 15px;
        color: #999999;
        top: -12px;
        font-family: "icomoon" !important;
      }
    }
  }

  .contestcard {
    background-color: $white;
    border-radius: 4px;
  }

  .contestname {
    background-color: #EDEDED !important;
    font-size: 14px;
    height: 38px;
  }

  .infobtn {
    height: 18px;
    width: 18px;
    opacity: 0.5;
    position: relative;
    bottom: 0px;
    right: 0px;
  }

  .entriesno {
    height: 21px;
    width: 280px;
    color: $black;
    font-family: $font-black;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    line-height: 21px;
    margin-bottom: 20px;
  }

  .autoRecurrent {
    height: 20px;
    width: 124px;
    color: $black;
    font-family: $font-bold;
    font-size: 16px;
    line-height: 20px;
  }

  .checkboxauto {
    height: 17px;
    width: 17px;
    border: 1px solid #979797;
    border-radius: 4px;
  }

  .autorecurrentdiv {
    margin-left: 25px;
    color: $black;
    font-family: $font-regular;
    margin-top: 20px;

    .custom-control-label {
      &::after {
        content: "";
        top: 5px;
        left: -19px;
        width: 6px;
        height: 10px;
        border: solid white;
        transform: rotate(45deg);
        border-width: 0 2px 2px 0;
        cursor: pointer;
      }
    }
  }

  .minmax-list {
    padding: 0;
  }

  .minmax-item {
    display: inline-block;
    width: 133px;

    @media (max-width: 1280px) {
      width: 120px;
    }

    .min-max-size {
      width: 133px;

      @media (max-width: 1280px) {
        width: 120px;
      }
    }
  }

  .infobtn {
    height: 18px;
    width: 18px;
    opacity: 0.5;
  }

  .multipleentries {
    height: 48px;
    width: 250px;
    margin-left: 20px;
    border: 1px solid #d2dfec;
    background-color: #f5f9fb;
  }

  .contestgroup {
    height: 48px;
    width: 250px;
    padding-left: 18px;
    border: 1px solid #d2dfec;
    border-radius: 4px 4px 4px 4px;
    background-color: #f5f9fb;
    color: $black;
    font-family: $font-bold;
    font-size: 16px;
    line-height: 20px;
    margin-left: 20px;
  }

  .minsize {
    height: 48px;
    width: 150px;
    color: $black;
    border: 1px solid #d2dfec;
    border-radius: 4px;
    background-color: #f5f9fb;
    margin-right: 20px;
    font-family: $font-regular;
    font-size: 16px;
    text-align: center;

    @media (max-width: 1400px) {
      width: 120px;
    }
  }

  .entryfee {
    height: 48px;
    width: 271px;
    border: 1px solid #d2dfec;
    background-color: #f5f9fb;
    padding: 10px;
    font-family: $font-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-left: 18px;
    margin-left: 10px;
    border-radius: 4px;

    @media (max-width: 1400px) {
      width: 200px;
    }

    &:focus {
      outline: none;
    }
  }

  .entry-fee-select {
    width: 50px;
    float: left;
    margin-left: 10px;
    margin-right: 6px;

    .Select-menu-outer {
      font-size: 11px;
    }


    .Select-control {
      width: 50px !important;
      border: none !important;
      background-color: transparent !important;
      margin: 0;

      &>:last-child {
        font-size: 10px;
        padding-top: 11px;
      }

      .Select-clear-zone {
        display: none;
      }

      .Select-multi-value-wrapper {
        width: 20px !important;

        .Select-value {
          padding-left: 6px;
        }

        .Select-value-label {
          font-size: 14px;
          font-size: 10px;
          line-height: 34px !important;
        }
      }

      .Select-arrow {
        border: none !important;

        &:after {
          content: "\e90f";
          border: none !important;
          position: absolute !important;
          height: 5px !important;
          width: 5px !important;
          right: 0px;
          top: -12px;
          color: $black;
          font-family: "icomoon" !important;
        }
      }
    }

    &.Select.is-open .Select-control .Select-arrow:after {
      content: "\e90f";
      border: none !important;
      position: absolute !important;
      height: 5px !important;
      color: $black !important;
      transform: rotate(-180deg);
      width: 5px !important;
      right: -13px !important;
      top: 5px;
      font-family: "icomoon" !important;
    }
  }

  .mar-10 {
    margin-bottom: 10px;
  }

  .tabbtn {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 40px;
    width: 258px;
  }

  .autobtn {
    height: 48px;
    min-width: 106px;
    border: 1px solid #d2dfec;
    background-color: #f5f9fb;
    border-radius: 4px;
    font-family: $font-regular;
    color: $black;
    font-size: 16px;
    padding: 0 12px;

    @media (max-width: 1280px) {
      min-width: 94px;
    }
  }

  .autobtn:hover {
    height: 48px;
    min-width: 106px;
    border: 1px solid #979797;
    border-radius: 4px 0 0 4px;
    background-color: white;
  }

  .btn-secondary:not(:disabled):not(.disabled) {
    transition: 1s;

    &.active {
      background-color: $secondary-color;
      border: 1px solid $secondary-color !important;
      color: $white;
      background-size: 100%;
      transition: 0s;
    }
  }

  .gwidth {
    font-size: 14px;
  }

  .destribution-tabs {
    .btn {
      @media (max-width: 1280px) {
        font-size: 12px;
        padding: 0px 5px;
      }
    }

    .btn-secondary:not(:disabled):not(.disabled).active {
      border-radius: 4px;

      @media (max-width: 1280px) {
        font-size: 12px;
        padding: 0px 5px;
      }
    }
  }

  .custombtn {
    height: 48px;
    border: 1px solid #979797;
    border-radius: 4px;
    font-family: $font-black;
    color: $black;
    font-size: 16px;
    line-height: 20px;
  }

  .custombtn:hover {
    height: 48px;
    border: 1px solid #979797;
    border-radius: 4px 0 0 4px;
    background-color: $white;
  }

  .gauranteedbtn {
    height: 48px;
    border: 1px solid #979797;
    border-radius: 4px;
    font-family: $font-black;
    color: $black;
    font-size: 16px;
    line-height: 20px;
  }

  .gauranteedbtn:hover {
    height: 48px;
    border: 1px solid #979797;
    background-color: white;
  }

  .winners-select-wrapper {
    .winners-select {
      margin-bottom: 35px;
    }
  }

  .prizepool {
    height: 48px;
    border: 1px solid #d2dfec;
    border-radius: 4px;
    background-color: #f5f9fb;
    font-family: $font-black;
    font-size: 16px;
    padding-left: 18px;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }

  .bonuscashbtn1 {
    height: 48px;
    width: 160px;
    border: 1px solid #d2dfec;
    background-color: #f5f9fb;
    border-radius: 4px;
    font-family: $font-regular;
    color: $black;
    font-size: 16px;

    @media (max-width: 1280px) {
      width: 140px;
    }

    .btn-secondary:not(:disabled):not(.disabled).active {
      background-color: $secondary-color;
      color: $white;
    }
  }

  /*winning table*/
  .winners-table {
    height: auto;
    min-width: 465px;
    width: 100%;
    margin-bottom: 15px;
    padding: 15px 5px;
    font-family: $font-black;
    border: 1px solid #d2dfec;
    border-radius: 2px;
    background-color: #f5f9fb;

    table {
      th {
        border-bottom: none;
        padding-bottom: 0;
      }

      #addr0 {
        height: 50px;
      }

      .cencle-btn {
        margin-top: -5px;
      }

      tr {
        td {
          border-top: none;
          padding: 0;
        }

        input {
          height: 32px;
          border: 1px solid #b7cade;
          opacity: 0.54;
          border-radius: 2px;
          background-color: $white;
        }

        .btn {
          padding: 0 5px;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .rank-td {
        width: 122px;

        input {
          width: 45px;
          float: left;
          margin-left: 10px;
        }
      }

      .percentage-td {
        width: 80px;

        input {
          width: 70px;
        }
      }
    }

    &.add-row {
      padding: 10px;
      display: inline-block;

      .btn {
        float: left;
        padding: 0.375rem 5px;

        &:focus {
          box-shadow: none;
        }
      }

      .total-wrapper {
        float: left;
        padding-top: 10px;
        width: 80%;

        ul {
          padding-left: 15px;
          list-style-type: none;
          margin-bottom: 0;

          li {
            display: inline-block;
            padding: 0;
            margin-left: 12px;

            span {
              font-weight: 600;
            }

            input {
              height: 32px;
              border: 1px solid #b7cade;
              opacity: 0.54;
              border-radius: 2px;
              background-color: $white;
              padding: 5px;
            }

            .percentage-input {
              width: 70px;
            }

            .total-amount-input {
              width: 195px;
            }
          }
        }
      }
    }
  }

  .entersiterank {
    height: 48px;
    width: 320px;
    border: 1px solid #d2dfec;
    border-radius: 4px;
    background-color: #f5f9fb;
    font-family: $font-black;
    font-size: 16px;
    padding-left: 18px;

    @media (max-width: 1280px) {
      width: 280px;
    }
  }
}

.create-dfs-contest {
  .livcardh3 {
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .card {
    border: none;
  }

  .carddiv {
    display: table;
    width: 100%;
    margin-top: 8px;

    .cardimg {
      width: 40px;
      height: 40px;
    }

    .col {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      padding: 0 !important;
      width: 25%;

      &:nth-child(2) {
        width: 50%;
      }

      @media (max-width: 1330px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .livcardh6dfs {
    height: 13px;
    width: 150px;
    color: #a8a8a8;
    font-family: "MuliBlack";
    font-size: 10px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
    margin-bottom: 4px;
  }

  .livcardh6dfs {
    height: 13px;
    width: 150px;
    color: #a8a8a8;
    font-family: "MuliBlack";
    font-size: 10px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
    margin-bottom: 4px;
  }

}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: "Muli-BoldItalic";
    font-size: 16px;
    margin-top: 20px;
    line-height: 1.3;
  }

  // Box.
  &+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    background: white;
    border: 1px solid #8f9ba6;
    position: absolute;
    left: -24px;
    top: 3px;
  }

  // Box focus
  &:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked+label:before {
    background: #F8436E;
    border-color: #F8436E;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: -21px;
    top: 10px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}


.set-prizes {
  color: #555555;
  font-family: $font-bold;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 22px;
}

.set-prize-option {
  input[type="checkbox"] {
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: 1px solid #979797;
    border-radius: 4px;
  }

  .prize-option {
    float: left;
    margin-right: 40px;
    font-size: 16px;
    font-family: $font-bold;
  }

  .opt-text {
    vertical-align: super;
    margin-left: 10px;
  }
}

.each {
  margin-left: 153px;
  margin-top: 2px;
  font-size: 10px;
}

.eachinput {
  margin-top: 15px;
}

.posclass {
  position: relative;
}

.create-dfs-contest {
  .gray-select-field.Select .Select-control {
    background-color: #ededed !important;
  }

  .Select-control>:last-child {
    padding-right: 11px;
  }

  .Select-value {
    border: 1px solid #d2dfec;
  }
}

.prdis-select {
  .Select-menu-outer {
    height: 100px !important;
    overflow: hidden;

    >div {
      height: 100%;
    }
  }
}

.mer-dis-note {
  background-color: #ffffdd;
  border-radius: 4px;
  box-shadow: 0px 3px 15px lightgrey;
  border: none;
  padding: 20px;
  margin-bottom: 8px;
  font-size: 16px
}
.dfs-create-tournament {
  margin-top: 36px;

  .dfs-white-box {
    background-color: $white;
    padding-bottom: 26px;
    //margin-top: 22px;
    box-shadow: 0 15px 45px 0 rgba(14, 22, 39, 0.1);

    .Select-control,
    .form-control {
      border: none;
      border-radius: 5px;
      height: 48px;
      background-color: #EDEDED;

      .Select-clear-zone {
        display: none;
      }
    }

    .f-disable {
      background-color: #B8B8B8 !important;

      .Select-control {
        background-color: #B8B8B8 !important;
      }
    }
  }

  .dfs-sel-league {
    .Select-control {
      background-color: #EDEDED !important;
    }

    .Select-value-label {
      line-height: 48px;
    }
  }

  .fixture-label {
    font-family: $font-bold;
    font-size: 18px;
  }

  .dfs-fixture-view {
    background-color: #EDEDED;
    padding: 30px 20px;
    border-radius: 6px;

    .fixture-view-header {
      display: flex;
      justify-content: space-between;
    }

    .line {
      background-color: #D8D8D8;
      height: 1px;
    }

    .select-all-parent {
      input[type="checkbox"] {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }

    .dfs-select-all {
      font-size: 16px;
      font-family: $font-bold;
      vertical-align: super;
    }

    .dfs-search-wrapper {
      .form-control {
        font-family: $font-regular;
        padding: 10px 30px 10px 20px;
        height: 36px;
        width: 310px;
        border-radius: 18px;
        background-color: $white;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
        margin-left: 22px;
      }

      i {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        z-index: 99;
        color: $gray-9;
        padding: 12px 4px;
        cursor: pointer;
      }
    }
  }

  .dfs-label {
    font-family: $font-bold;
    margin-bottom: 8px;
    color: $gray-5;
    font-size: 16px;
    display: block;
  }

  // .react-datepicker-wrapper {
  //   display: block;
  // }

  .dfs-datep {
    height: 48px;
    width: 94%;
    background-color: #EDEDED;
    border: none;
    border-radius: 4px;
    padding: 8px;
  }

  .dfs-sel-league {
    width: 100%;
    border-radius: 4px;
    background-color: #EDEDED;
  }

  .right-selection {
    cursor: pointer;
    height: 24px;
    width: 24px;
    background-color: #2A2E49;
    position: absolute;
    right: 15px;
    justify-content: flex-end;
    top: 10px;
    padding-left: 0px;
    align-items: center;
    border-bottom-left-radius: 27px;
    border-top-right-radius: 12px;

    @media (min-width: 1650px) {
      top: 12px;
      right: 22px;
    }

    .rght-img {
      width: 24px;
      height: 22px;
    }
  }

  .fixture-row {
    margin-left: 0;
    margin-right: 0;
  }

  .fixture-data {
    padding: 10px;
    margin-top: 10px;
    height: 80px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    .center-view {
      display: flex;
      flex-direction: column;
      margin: 8px;
    }

    .team-image {
      height: 35px;
      width: 35px;

    }

    .team-name {
      color: #212121;
      font-family: $font-bold;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      text-align: center;
    }

    .time {
      color: #EB4A3C;
      font-family: $font-bold;
      font-size: 10px;
      font-weight: bold;
      line-height: 13px;
      text-align: center;
    }
  }

  .right-item {
    display: flex;

  }

  .dfs-filter-dopdown {
    height: 35px;
    width: 100px;
    border-radius: 15px;
    background-color: $white;

    .Select-menu-outer {
      margin-top: 2px;
      border-radius: 0;
      border: none;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);

      div {
        border-radius: 0;
        background-color: transparent;

        &:hover {
          background-color: transparent;
          border-radius: 0;
        }
      }
    }

    .Select-control {
      border: none;
      border-radius: 15px;
      height: 35px;
      background-color: $white;

      .Select-clear-zone {
        display: none;
      }

      .Select-placeholder {
        padding-top: 7px;
        padding-left: 12px;
      }

      .Select-value {
        padding-top: 2px;
        padding-right: 0px !important;
      }

      .Select-multi-value-wrapper {
        height: 35px;

        .Select-value-label {
          color: $black !important;
          font-family: $font-bold;
          font-size: 12px;
        }
      }
    }
  }

  .dfs-upload {
    box-sizing: border-box;
    height: 30px;
    width: 150px;
    border: 1px solid $gray-9;
    border-radius: 4px;
    color: $gray-9;
    font-family: $font-bold;
    font-size: 16px;
    padding-top: 2px;
    text-align: center;
    cursor: pointer;
  }

  .dfs-uplogo-box {
    .uplogo-btn {
      float: left;
      margin-right: 80px;
    }

    .dfs-image-box {
      float: left;
      width: 280px;
      height: 150px;

      .img-cover {
        border-radius: 8px;
        object-fit: contain;
      }

    }
  }

  .dfs-up-btn {
    position: absolute;
    opacity: 0;
  }

  .dfs-remove-img {
    color: $secondary-color;
    font-family: $font-bold;
    font-size: 16px;
    text-align: center;
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
  }

  .dfs-sel-fx {
    color: $gray-9;
  }

  .dfs-uplogo-box {
    margin-top: 26px;
  }

  .dfs-btn-sub {
    text-align: center;
    margin-top: 28px;

    .btn {
      height: 36px;
      width: 132px;
    }
  }

  .dfs-sponsor-box {
    .com-chekbox-container {
      font-size: 16px;
    }
  }

  .dfs-btn-txt {
    float: left;
    text-align: center;

    input[type="file"] {
      width: 150px;
    }
  }

  .dfs-banner-sz {
    color: $gray-9;
    font-family: $font-bold;
    font-size: 12px;
    margin-top: 6px;
  }

  .dfs-banner-text {
    margin-top: 8px;
    float: left;
    margin-left: 16px;
    color: $gray-9;
    font-family: $font-bold;
    font-size: 12px;
  }

  .dfs-banner-img {
    width: 100%;
    max-width: 334px;
    height: 60px;
    margin-top: 30px;
    position: relative;

    .icon-close {
      cursor: pointer;
      position: absolute;
      color: $white;
      right: -10px;
      top: -10px;
      padding: 4px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 100%;
    }
  }

  /* Start distribution table  */
  .prize-dist-table {
    .prize-type {
      .Select-control {
        height: 38px !important;
      }
    }

    .form-control {
      height: 38px !important;
    }

    .head-title {
      margin: 14px 70px;
    }

    .rank-input {
      width: 80px;
    }

    .prize-type {
      width: 200px;
    }

    .fx-distribution {
      .form-control {
        width: 200px;
      }
    }

    .dfs-btn-wdt {
      width: 70%;
      margin: 0 auto;
    }

    .add-prize-btn {
      // width: 75%;
      // float: left;
      max-width: 600px;
    }

    .dfs-tot-dtl {
      color: #030F09;
      font-family: $font-bold;
      font-size: 16px;
    }

    .dfs-real {
      color: $gray-9;
    }

    .dfs-total-dis {
      text-align: center;
      width: 30%;
      float: left;
    }

    .dfs-tot-sty {
      margin: 34px 40px;
      float: left;
    }

    .dfs-total-prz {
      margin-left: 34px;
      margin-top: 20px;
    }
  }

  .pd-select {
    .Select-control {
      background-color: #EDEDED !important;
    }
  }

  /*End distribution table */
  .view-picks {
    margin: 0;

    .home-left,
    .home-right {
      min-height: 80px;
      height: 80px;
    }

    .cricket-fixture-card {
      margin-top: 12px;
      margin-bottom: 0;
      min-height: 100px;
    }

    .live-comp-date {
      border-bottom: 1px solid #EAEAEA;
    }
  }

  .dfs-setp-stype {
    float: left;
    width: 300px;
  }

  .pick-option {
    font-size: 12px;
    margin-top: 6px;
  }

  .dfs-cur-sel {
    float: left;
    width: 58px;
    margin-right: 4px;

    .Select-control {
      background-color: #EDEDED !important;
    }

    .Select-value-label {
      line-height: 48px;
    }
  }

  .t-name {
    float: left;
    max-width: 150px;
  }

  .dfs-ml-minus {
    margin-left: -82px;

    @media (max-width: 1281px) {
      margin-left: -62px;
    }

    @media (min-width: 1500px) {
      margin-left: -126px;
    }
  }

  .dfst.common-fixture {
    .bg-card {
      height: 90px;
      padding: 20px 16px 12px;

      .right-selection {
        right: 10px;
      }
    }
  }
  .ent-text{
    margin-top: 4px;
    float: left;
    width: 100%;
  }
}

.rank-add{
  width: 225px;
}